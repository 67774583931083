import React from 'react';
import { AppBar, Box, Toolbar, useTheme } from '@mui/material';
import logo from '../../img/LieksBoetjeLogo.png';

function TopBar() {
  const { palette } = useTheme();

  return (
    <AppBar position="sticky" sx={{ backgroundColor: 'white', borderRadius: '1px' }}>
      <Toolbar>
        <Box
          sx={{
            m: '10px',
            display: 'flex',
            justifyContent: { xs: 'center', sm: 'flex-start' }, // Center on small screens, flex-start on larger screens
            alignItems: 'center',
            width: '100%', // Ensure the box takes full width
          }}
        >
          <img src={logo} alt='topbar-logo' width={200} />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
