import React, { useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import Image from 'mui-image';
import foto1 from '../img/slideimages/img (1).jpg';
import foto2 from '../img/slideimages/img (2).jpg';
import foto3 from '../img/slideimages/img (3).jpg';
import foto4 from '../img/slideimages/img (4).jpg';
import foto5 from '../img/slideimages/img (5).jpg';
import foto6 from '../img/slideimages/img (6).jpg';
import foto7 from '../img/slideimages/img (7).jpg';
import foto12 from '../img/slideimages/img (12).jpg';
import foto13 from '../img/slideimages/img (13).jpg';
import foto14 from '../img/slideimages/img (14).jpg';

const images = [foto1, foto2, foto3, foto4, foto5, foto6, foto7, foto12, foto13, foto14];

const ImageCarousel = () => {
  const { palette } = useTheme();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [nextImageIndex, setNextImageIndex] = useState(1);
  const [opacity, setOpacity] = useState(1);

  const handleNextClick = () => {
    setNextImageIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
    setOpacity(0);
    setTimeout(() => {
      setCurrentImageIndex(nextImageIndex);
      setOpacity(1);
    }, 1000); // Adjust the delay to match the transition duration
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleNextClick();
    }, 2700);

    return () => clearTimeout(timeoutId);
  }, [currentImageIndex]);

  return (
        <Image src={images[currentImageIndex]} sx={{maxHeight:{xl:'800px', sm:'600px', xs:'600px'}, minHeight:{xl:'800px', sm:'600px', xs:'600px'}}} fit='cover'/>
  );
};

export default ImageCarousel;
