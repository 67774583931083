import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#2f363b',
            light: '#656565'
        },

        secondary: {
            main: '#5FFF15',
            white: '#FAF4DD'
        },
    },
    typography: {
        fontFamily: 'Cormorant Infant',
    },
})

export default theme