import { CssBaseline, ThemeProvider } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import HomePage from './scenes/home/HomePage'
import LandingPage from './scenes/landingpage';
import theme from './theme'
import './App.css'

function App() {
  return (
    <CssBaseline>
      <ThemeProvider theme={theme}>
          <Routes>
            <Route path='/' element={<LandingPage />} />
            <Route path='/home' element={<HomePage/>} />
          </Routes>
      </ThemeProvider>
    </CssBaseline>
  );
}

export default App;
