import React from 'react'
import { Box, Button, Card, CardContent, Grid, Typography, useTheme } from '@mui/material';

const ScheduleCards = () => {
    const { palette } = useTheme();

  return (
    <Grid container spacing={2}>
    <Grid item xl={3} md={6} sm={12}>
      <Card sx={{
        backgroundColor: `${palette.primary.main}`,
        color: 'white',
        minHeight:'264px',
      }}>
        <CardContent>
          <Typography variant="h5" textAlign='center' fontWeight='500'>
            Lieks Ochtend Workshop
          </Typography>
          <Typography textAlign='center' variant='h6'>
            Start ochtend workshop: 10:00 <br />
            Koffie en Thee  <br />
            Een Lekkere snack <br />
            Prijs afhankelijk van werkstuk
          </Typography>
        </CardContent>
      </Card>
    </Grid>
    <Grid item xl={3} md={6} sm={12}>
      <Card sx={{
        backgroundColor: `${palette.primary.main}`,
        color: 'white',
        minHeight:'264px',
      }}>
        <CardContent>
          <Typography variant="h5" textAlign='center' fontWeight='500'>
            Lieks Middag Workshop
          </Typography>
          <Typography textAlign='center' variant='h6'>
            Start middag workshop: 13:30 <br />
            Koffie en Thee  <br />
            Een Lekkere snack
            <br />
            Prijs afhankelijk van werkstuk
          </Typography>
        </CardContent>
      </Card>
    </Grid>
    <Grid item xl={3} md={6} sm={12}>
      <Card sx={{
        backgroundColor: `${palette.primary.main}`,
        color: 'white',
        minHeight:'264px',
      }}>
        <CardContent>
          <Typography variant="h5" textAlign='center' fontWeight='500'>
            Lieks Avond Workshop
          </Typography>
          <Typography textAlign='center' variant='h6'>
            Start ochtend workshop: 19:30 <br />
            Koffie en Thee  <br />
            Een Lekkere snack
            <br />
            Prijs afhankelijk van werkstuk
          </Typography>
        </CardContent>
      </Card>
    </Grid>
    <Grid item xl={3} md={6} sm={12}>
    <Card sx={{
        backgroundColor: `${palette.primary.main}`,
        color: 'white',
        minHeight:'264px',
        width:{xl:'auto', lg:'auto',md:'auto', sm:'auto', xs:'275px'}
      }}>
        <CardContent>
          <Typography variant="h5" textAlign='center' fontWeight='500'>
          Lieks Dag Workshop
          </Typography>
          <Typography textAlign='center' variant='h6'>
            Start Dag workshop: 10:00 <br />
            Eind Tijd: 16:00<br />
            Koffie en Thee<br />
            Een Lekkere snack<br/>
            Prijs: 85,50- <br/>

          </Typography>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
  )
}

export default ScheduleCards
