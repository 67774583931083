import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import Typewriter from 'typewriter-effect';
import { useNavigate } from 'react-router-dom';
import './index.css';

const App = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const [fade, setFade] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFade(true);
      setTimeout(() => {
        navigate('/home');
      }, 1000); // Change this value to adjust the fade-out duration
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [navigate]);;

  return (
    <div className={`landingpage-wrapper ${fade ? 'fade-out' : ''}`}>
      <Box sx={{mb:'100px'}}>
          <Typography color="white" sx={{fontSize:{xl:'128px', sm:'50px', xs:'50px'}}}>
            <Typewriter options={{ strings: ['Lieks Boetje'], autoStart: true, loop: true, }} />
          </Typography>
      </Box>
    </div>
  );
};

export default App;
