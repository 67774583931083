import React from 'react';
import { Box, Button, Card, CardContent, Grid, Typography, useTheme } from '@mui/material';
import TopBar from '../topbar/TopBar';
import ImageCarousel from '../../components/ImageSlider';
import ScheduleCards from '../../components/cards/ScheduleCards';
import { useNavigate } from 'react-router-dom';

function HomePage() {
  const { palette } = useTheme();
  const navigate = useNavigate();
  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <TopBar />
      <ImageCarousel />
      <Box>
        <Typography color='white' sx={{ textAlign: 'center', fontSize: { xl: '70px', sm: '60px', xs: '30px' } }}>VANAF 30 NOVEMBER 2023</Typography>
      </Box>
      <Box sx={{ height: { xl: '1273px' }, background: 'radial-gradient(circle at 11% 0%, rgba(95, 255, 21, 1) 0%, rgba(47, 54, 59, 1) 40%)', p: '50px' }}>
        <ScheduleCards />
      </Box>
    </Box>
  );
}

export default HomePage;

